import axios from "axios";
import { faker } from "@faker-js/faker";

const paginate = (array, page_size, page_number) => {
  return array.slice((page_number - 1) * page_size, page_number * page_size);
};

export const getHandoverList = (params) => {
  return new Promise((resolve, reject) => {
    const datas = Array.from({ length: 35 }).map((x) => ({
      submission_date: faker.datatype.datetime(),
      nap: faker.datatype.uuid(),
      id: faker.datatype.uuid(),
      debitur: faker.name.fullName(),
      handover_date: faker.datatype.number(10),
      category: faker.helpers.arrayElement([
        "Diserahkan kepada pihak lain",
        "Diserahkan kepada debitur",
      ]),
      submit_user: faker.name.fullName(),
      handover_user: faker.name.fullName(),
      status: faker.helpers.arrayElement([
        "Permintaan Serah Terima",
        "PUPN",
        "Rusak",
        "Sudah Tidak Ada",
      ]),
    }));
    let newData = paginate(datas, params.limit, params.page);
    const pageCount = Math.round(Math.ceil(datas.length / params.limit));

    const pagination = {
      itemCount: datas.length,
      limit: params.limit,
      pageCount,
      page: params.page,
      hasPrevPage: params.page - 1 === 0 ? false : true,
      hasNextPage: params.page + 1 > pageCount ? false : true,
      prevPage: params.page - 1 === 0 ? null : params.page - 1,
      nextPage: params.page + 1 > pageCount ? null : params.page + 1,
    };
    const newObject = {
      result: newData,
      pagination,
    };
    setTimeout(() => {
      resolve(newObject);
    }, 2000);
  });
};

export const getTrees = (params) => {
  return new Promise((resolve, reject) => {
    const datas = Array.from({ length: 35 }).map((x) => ({
      collateral_value: 30000000,
      tree_number: faker.datatype.uuid(),
      fiduciary: faker.datatype.uuid(),
      tree_name: "Jati",
      wide: 15,
      debitur: faker.name.fullName(),
      status: faker.helpers.arrayElement(["PUPN", "Realease Lunas"]),
    }));
    let newData = paginate(datas, params.limit, params.page);
    const pageCount = Math.round(Math.ceil(datas.length / params.limit));

    const pagination = {
      itemCount: datas.length,
      limit: params.limit,
      pageCount,
      page: params.page,
      hasPrevPage: params.page - 1 === 0 ? false : true,
      hasNextPage: params.page + 1 > pageCount ? false : true,
      prevPage: params.page - 1 === 0 ? null : params.page - 1,
      nextPage: params.page + 1 > pageCount ? null : params.page + 1,
    };
    const newObject = {
      result: newData,
      pagination,
    };
    setTimeout(() => resolve(newObject), 1000);
  });
};

export const getLands = (params) => {
  return new Promise((resolve, reject) => {
    const datas = Array.from({ length: 35 }).map((x) => ({
      collateral_value: 100000000,
      tree_number: faker.datatype.uuid(),
      fiduciary: faker.datatype.uuid(),
      asset_name: faker.commerce.productName(),
      location: faker.address.cityName(),
      storage_location: faker.address.cityName(),
      wide: faker.datatype.number(1000),
      certificate_number: faker.datatype.uuid(),
      status: faker.helpers.arrayElement(["PUPN", "Realease Lunas", "Rusak"]),
    }));
    let newData = paginate(datas, params.limit, params.page);
    const pageCount = Math.round(Math.ceil(datas.length / params.limit));

    const pagination = {
      itemCount: datas.length,
      limit: params.limit,
      pageCount,
      page: params.page,
      hasPrevPage: params.page - 1 === 0 ? false : true,
      hasNextPage: params.page + 1 > pageCount ? false : true,
      prevPage: params.page - 1 === 0 ? null : params.page - 1,
      nextPage: params.page + 1 > pageCount ? null : params.page + 1,
    };
    const newObject = {
      result: newData,
      pagination,
    };
    setTimeout(() => resolve(newObject), 2000);
  });
};

export const getVehicles = (params) => {
  return new Promise((resolve, reject) => {
    const datas = Array.from({ length: 35 }).map((x) => ({
      vehicle_name: faker.vehicle.vehicle(),
      fiduciary: faker.datatype.uuid(),
      quantity: faker.datatype.number(100),
      age: faker.datatype.number(20),
      status: faker.helpers.arrayElement([
        "PUPN",
        "Realease Lunas",
        "Rusak",
        "Sudah Tidak Ada",
      ]),
    }));
    let newData = paginate(datas, params.limit, params.page);
    const pageCount = Math.round(Math.ceil(datas.length / params.limit));

    const pagination = {
      itemCount: datas.length,
      limit: params.limit,
      pageCount,
      page: params.page,
      hasPrevPage: params.page - 1 === 0 ? false : true,
      hasNextPage: params.page + 1 > pageCount ? false : true,
      prevPage: params.page - 1 === 0 ? null : params.page - 1,
      nextPage: params.page + 1 > pageCount ? null : params.page + 1,
    };
    const newObject = {
      result: newData,
      pagination,
    };
    setTimeout(() => resolve(newObject), 2000);
  });
};

export const getCollaterals = (params) => {
  return new Promise((resolve, reject) => {
    const datas = Array.from({ length: 35 }).map((x) => ({
      collateral_value: 100000000,
      bilyet: faker.datatype.uuid(),
      fiduciary: faker.datatype.uuid(),
      name: faker.name.fullName(),
      bank: "Bank Mandiri",
      wide: faker.datatype.number(1000),
      debitur: faker.name.fullName(),
      storage_location: faker.address.cityName(),
      status: faker.helpers.arrayElement([
        "PUPN",
        "Realease Lunas",
        "Rusak",
        "Sudah Tidak Ada",
      ]),
    }));
    let newData = paginate(datas, params.limit, params.page);
    const pageCount = Math.round(Math.ceil(datas.length / params.limit));

    const pagination = {
      itemCount: datas.length,
      limit: params.limit,
      pageCount,
      page: params.page,
      hasPrevPage: params.page - 1 === 0 ? false : true,
      hasNextPage: params.page + 1 > pageCount ? false : true,
      prevPage: params.page - 1 === 0 ? null : params.page - 1,
      nextPage: params.page + 1 > pageCount ? null : params.page + 1,
    };
    const newObject = {
      result: newData,
      pagination,
    };
    setTimeout(() => resolve(newObject), 2000);
  });
};
// export const getHandoverList = async (params) => {
//   const {data} = await axios.get(`${process.env.REACT_APP_BASE_API_URL}/perhimpunan-dana/v1/bank-account`, {params});
//   return data
// };

export const getAgencyCollateral = async (params, signal) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_BASE_API_URL}/fdb-v2/v1/cms/agency-collateral`, {params, signal}
  );
  return data;
};

export const getAgencyCollateralTable = async (params, signal) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_BASE_API_URL}/fdb-v2/v1/cms/agency-collateral/${params.request_id}`, {params, signal}
  );
  return data;
};

export const getCollateralsList = async (params, signal) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_BASE_API_URL}/perjanjian/v1/agreement-agency-collateral`, {params, signal}
  );
  return data;
};

export const createHandover = async (payload) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_BASE_API_URL}/perjanjian/v1/agreement-agency-collateral`,
    payload
  );

  return data;
};

export const getHandoverById = async (params) => {
  console.log('params', params)
  const {data} = await axios.get(`${process.env.REACT_APP_BASE_API_URL}/perjanjian/v1/agreement-agency-collateral/${params.id}`);
  return data
}
