import React, { useMemo, useCallback, useState, useEffect } from "react";
import ReactSelect from "react-select";
import { useFormContext, Controller } from "react-hook-form";

let NEXT_PAGE = null;

const CustomSelectFormv2 = ({
  placeholder = "Cari ...",
  options,
  onValueChange = (val) => {},
  handleScrollBottom = () => {},
  asText = false,
  asTextClass,
  ...props
}) => {
  const { name } = props;
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={null}
      render={({
        field: { onChange, value, onBlur },
        fieldState: { invalid, error },
      }) => (
        <div className="w-full">
          {asText ? (
            <div
            className={[
              "min-h-[2.5rem] py-2 overflow-hidden text-ellipsis",
              asTextClass,
            ].join(" ")}
          >
            {["string", "number"].includes(typeof value) || !value
              ? value
              : value?.label || JSON.stringify(value)}
          </div>
          ): (
            <ReactSelect
              {...props}
              placeholder={placeholder}
              value={value}
              options={options}
              onChange={(val) => {
                onChange(val);
                onValueChange(val);
              }}
              onBlur={onBlur}
              onMenuScrollToBottom={handleScrollBottom}
              styles={{
                placeholder: (styles) => ({
                  ...styles,
                  color: "#98A2B3",
                  fontSize: 14,
                  fontWeight: 400,
                }),
                control: (styles) => ({
                  ...styles,
                  borderColor: invalid ? "#dc3545" : "#EAECF0",
                  ":hover": {
                    ...styles[":hover"],
                    borderColor: invalid ? "#dc3545" : "#EAECF0",
                  },
                  boxShadow: "none",
                  borderRadius: "6px",
                }),
                singleValue: (styles) => ({
                  ...styles,
                  color: "#000",
                }),
                indicatorContainer: (styles, { isFocused }) => ({
                  ...styles,
                  color: isFocused ? "#98A2B3" : "#98A2B3",
                  ":hover": {
                    ...styles[":hover"],
                    color: "#98A2B3",
                  },
                }),
                option: (
                  styles,
                  { data, isSelected, isFocused, isDisabled }
                ) => ({
                  ...styles,
                  cursor: isDisabled ? "not-allowed" : "auto",
                  backgroundColor: isDisabled
                    ? undefined
                    : isSelected
                    ? "#01A24A"
                    : isFocused
                    ? "#EEFFF4"
                    : undefined,
                  color: isDisabled
                    ? "hsl(0, 0%, 80%)"
                    : isSelected
                    ? "#fff"
                    : isFocused
                    ? "#077439"
                    : data.color,
                }),
              }}
              menuPortalTarget={document.body}
            />

          )}
          {invalid && (
            <div className="text-xs text-error-600 mt-2">{error?.message}</div>
          )}
        </div>
      )}
    />
  );
};

export default React.memo(CustomSelectFormv2);
