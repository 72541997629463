import React, { useCallback, useEffect, useMemo, useState } from "react";
import { PiExportBold } from "react-icons/pi";

import { Breadcrumbs, CustomSelect, TitleText, Button, Tabs, Spinner, Table } from "components";
import ExampleMap from "assets/img/example-map.png";
import ExampleMap2 from "assets/img/example-map-2.png";
import TreeTable from "../Handover/Tables/TreeTable";
import VehicleTable from "../Handover/Tables/VehicleTable";
import LandTable from "../Handover/Tables/LandTable";
import CollateralTable from "../Handover/Tables/CollateralTable";
import { useDashobardCollateral, useDashobardCollateralTable } from "hooks/collateralDashboard";
import { useProvinceMasterData } from "hooks/masterData";

const links = [
  {
    label: "Dashboard Collateral",
  },
];

const locationArray = [
  {
    value: "indonesia",
    label: "Seluruh Indonesia",
    image: ExampleMap,
  },
  {
    value: "jakarta",
    label: "Provinsi DKI Jakarta",
    image: ExampleMap2,
  },
  {
    value: "jabar",
    label: "Provinsi Jawa Barat",
  },
];

const tabItems = [
  {
    id: "tally_sheet",
    label: "Agunan Pohon",
  },
  {
    id: "cash_collateral",
    label: "Cash Collateral",
  },
  {
    id: "assets",
    label: "Tanah & Bangunan",
  },
];

const CollateralDashboard = () => {
  const [locationFilter, setLocationFilter] = useState();
  const [provinceFilter, setProvinceFilter] = useState({})
  const [activeTab, setActiveTab] = useState("tally_sheet");
  const [provinceOptions, setProvinceOptions] = useState([{
    label: "Semua Provinsi",
    value: "all",
  }])
  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1)

  const onChangeLocation = useCallback(
    (obj) => {
      if (obj.value !== 'all') {
        setProvinceFilter(obj);
      } else {
        setProvinceFilter({})
      }
    },
    []
  );
  const onChangeTab = useCallback((active) => {
    setActiveTab(active);
  }, []);

  const { data: cashCollateralData, isLoading: cashCollateralDataLoading } = useDashobardCollateral(["cash_collateral", { type: 'cash_collateral', province_id: provinceFilter?.value }])
  const { data: tallySheetData, isLoading: tallySheetDataLoading } = useDashobardCollateral(["tally_sheet", { type: 'tally_sheet', province_id: provinceFilter?.value }])
  const { data: assetsCollateralData, isLoading: assetsCollateralDataLoading } = useDashobardCollateral(["assets", { type: 'assets', province_id: provinceFilter?.value }])

  const { data: provinceData, isLoading: isLoadingProvince } = useProvinceMasterData(["province", { province_id: provinceFilter?.value }])

  const { data: collateralTable, isLoading: isLoadingCollateralTable } = useDashobardCollateralTable(["collateral_dashboard_table", { type: activeTab, province_id: provinceFilter?.value, limit, page }])

  useEffect(() => {
    if (provinceData) {
        const newOptions = provinceData.data?.map(item => ({
            label: item.namaProvinsi,
            value: item.id,
        })) || []; // Default to an empty array if provinceData.data is undefined

        setProvinceOptions(prevOptions => [
            ...prevOptions, // Spread the existing options
            ...newOptions, // Add the new options
        ]);
    }
  }, [provinceData]);

  const total = useMemo(() => {
    const count = (cashCollateralData?.result ?? 0) + 
                  (tallySheetData?.result ?? 0) + 
                  (assetsCollateralData?.result ?? 0);

    return count;
  }, [assetsCollateralData?.result, cashCollateralData?.result, tallySheetData?.result]);

  const onChangePage = useCallback((val) => setPage(val), []);
  const onChangeRowsPerPage = useCallback((val) => setLimit(val), []);

  const treeColumns = useMemo(() => {
    return [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        render: (value, data, index) => {
          const no =
            parseInt(page) * parseInt(limit) - parseInt(limit) + index + 1;
          return no + ".";
        },
      },
      {
        id: "debtor_name",
        title: "Nama Lembaga",
        dataIndex: "debtor_name",
        sortable: true,
        render: (value, data, index) => (value ? `${value}` : "-"),
      },
      {
        id: "tree_number",
        title: "No Pohon",
        dataIndex: "tree_number",
        sortable: true,
        render: (value, data, index) => value ?? "-",
      },
      {
        id: "name",
        title: "Nama Pohon",
        dataIndex: "name",
        sortable: true,
        render: (value, data, index) => (value ? `${value}` : "-"),
      },
      {
        id: "area",
        title: "Ukuran",
        dataIndex: "area",
        sortable: true,
        render: (value, data, index) => (value ? `${value} cm` : "-"),
      },
      {
        id: "province",
        title: "Provinsi",
        dataIndex: "province",
        sortable: true,
        render: (value, data, index) => (value ? `${value}` : "-"),
      },
      {
        id: "price",
        title: "Nilai Agunan",
        dataIndex: "price",
        sortable: true,
        render: (value, data, index) =>
          value
            ? new Intl.NumberFormat("id-ID", {
                style: "currency",
                currency: "IDR",
              }).format(value)
            : "-",
      },
    ]
  }, [])

  const lembagaColumns = useMemo(() => {
    return [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        render: (value, data, index) => {
          // const no =
          //   parseInt(page) * parseInt(limit) - parseInt(limit) + index + 1;
          // return no + ".";
          return "2"
        },
      },
      {
        id: "type",
        title: "Tipe Assets",
        dataIndex: "type",
        sortable: true,
        render: (value, data, index) => value ?? "-",
      },
      {
        id: "debtor_name",
        title: "Nama Lembaga",
        dataIndex: "debtor_name",
        sortable: true,
        render: (value, data, index) => (value ? `${value}` : "-"),
      },
      {
        id: "form",
        title: "Form Asset",
        dataIndex: "form",
        sortable: true,
        render: (value, data, index) => (value ? `${value}` : "-"),
      },
      {
        id: "province",
        title: "Provinsi",
        dataIndex: "province",
        sortable: true,
        render: (value, data, index) => (value ? `${value}` : "-"),
      },
      {
        id: "value",
        title: "Nilai Agunan",
        dataIndex: "value",
        sortable: true,
        render: (value, data, index) =>
          value
            ? new Intl.NumberFormat("id-ID", {
                style: "currency",
                currency: "IDR",
              }).format(value)
            : "-",
      },
    ]
  // }, [page, limit])
  }, [])

  return (
    <div className="flex flex-col space-y-6">
      <Breadcrumbs items={links} />

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        <TitleText className="self-center">Dashboard Collateral</TitleText>
        <CustomSelect
          optionsData={provinceOptions}
          placeholder="Status"
          className="rounded-md border-gray-200 border-solid text-gray-400 text-sm outline-none focus:outline-none"
          onChange={onChangeLocation}
          value={provinceFilter?.value ? provinceFilter : {
            label: 'Seluruh Indonesia',
            value: "all"
          }}
          isClearable={false}
        />
      </div>
      <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
        <div className="card-body p-6">
          <div className="space-y-6">
            <div className="flex space-x-4 items-center">
              <TitleText className="flex-1">{provinceFilter?.value ? `Provinsi ${provinceFilter?.label} ` : "Seluruh Indonesia"}</TitleText>
              {/* <Button
                className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
                startIcon={<PiExportBold className="text-white" size={20} />}
              >
                Export
              </Button> */}
            </div>
            {/* {locationFilter.value === "indonesia" && ( */}
              <div className="card w-full bg-primary-50 shadow-sm rounded-xl border-primary-500 border-solid border-[1px]">
                <div className="card-body p-6">
                  <div className="space-y-6">
                    <div className="flex flex-col space-y-2">
                      <div className="text-sm text-gray-600">
                        Total Nilai Semua Agunan
                      </div>
                      <div className="text-2xl text-gray-900 font-semibold">
                        {
                          cashCollateralDataLoading && tallySheetDataLoading && assetsCollateralDataLoading ? (
                            <div className="relative w-full h-full">
                              <Spinner className="text-[40px]" />
                            </div>
                          ) : (
                            new Intl.NumberFormat("id-ID", {
                              style: "currency",
                              currency: "IDR",
                              maximumFractionDigits: 0,
                            }).format(
                              total
                            )
                          )
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            {/* )} */}
            <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-4">
              <div className="card w-full bg-white shadow-sm rounded-xl border-[#E0E0E0] border-solid border-[1px] relative">
                <div className="border-l-[4px] border-[#0086C9] rounded-tl-xl rounded-bl-xl absolute left-0 right-0 bottom-0 top-0"></div>
                <div className="card-body p-6">
                  <div className="flex flex-col space-y-3">
                    <div className="text-sm text-gray-600">
                      Total Cash Collateral
                    </div>
                    <div className="flex flex-col space-y-2">
                      <div className="bg-[#ECFDF3] px-2.5 py-0.5 rounded-2xl flex items-center w-fit">
                        <div className="text-sm text-[#027A48]">1000</div>
                      </div>
                      <div className="break-all text-lg font-semibold text-[#0086C9]">
                        {
                          cashCollateralDataLoading ? (
                            <React.Fragment>
                              <div className="relative w-full h-full">
                                <Spinner className="text-[40px]" />
                              </div>
                            </React.Fragment>
                          ) : new Intl.NumberFormat("id-ID", {
                            style: "currency",
                            currency: "IDR",
                            maximumFractionDigits: 0,
                          }).format(cashCollateralData?.result || 0)
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card w-full bg-white shadow-sm rounded-xl border-[#E0E0E0] border-solid border-[1px] relative">
                <div className="border-l-[4px] border-[#DD2590] rounded-tl-xl rounded-bl-xl absolute left-0 right-0 bottom-0 top-0"></div>
                <div className="card-body p-6">
                  <div className="flex flex-col space-y-3">
                    <div className="text-sm text-gray-600">
                      Total Tanah & Bangunan
                    </div>
                    <div className="flex flex-col space-y-2">
                      <div className="bg-[#ECFDF3] px-2.5 py-0.5 rounded-2xl flex items-center w-fit">
                        <div className="text-sm text-[#027A48]">1000</div>
                      </div>
                      <div className="break-all text-lg font-semibold text-[#DD2590]">
                      {
                          assetsCollateralDataLoading ? (
                            <React.Fragment>
                              <div className="relative w-full h-full">
                                <Spinner className="text-[40px]" />
                              </div>
                            </React.Fragment>
                          ) : new Intl.NumberFormat("id-ID", {
                            style: "currency",
                            currency: "IDR",
                            maximumFractionDigits: 0,
                          }).format(assetsCollateralData?.result || 0)
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card w-full bg-white shadow-sm rounded-xl border-[#E0E0E0] border-solid border-[1px] relative">
                <div className="border-l-[4px] border-[#15B79E] rounded-tl-xl rounded-bl-xl absolute left-0 right-0 bottom-0 top-0"></div>
                <div className="card-body p-6">
                  <div className="flex flex-col space-y-3">
                    <div className="text-sm text-gray-600">Total Pohon</div>
                    <div className="flex flex-col space-y-2">
                      <div className="bg-[#ECFDF3] px-2.5 py-0.5 rounded-2xl flex items-center w-fit">
                        <div className="text-sm text-[#027A48]">1000</div>
                      </div>
                      <div className="break-all text-lg font-semibold text-[#15B79E]">
                      {
                          tallySheetDataLoading ? (
                            <React.Fragment>
                              <div className="relative w-full h-full">
                                <Spinner className="text-[40px]" />
                              </div>
                            </React.Fragment>
                          ) : new Intl.NumberFormat("id-ID", {
                            style: "currency",
                            currency: "IDR",
                            maximumFractionDigits: 0,
                          }).format(tallySheetData?.result || 0)
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mb-6">
        <Tabs items={tabItems} onTabChanged={onChangeTab} />
      </div>
      {/* {RenderedTab} */}
      {/* {locationFilter.value === "indonesia" ? (
        <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px] min-h-[150px] justify-center">
          <div className="text-gray-500 text-center">
            Silahkan pilih filter salah satu dari wilayah yang ingin diliihat
            detailnya
          </div>
        </div>
      ) : ( */}
        <div className="space-y-4">
          <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between gap-2">
            <div className="font-semibold text-gray-900">
              {`${tabItems.find((x) => x.id === activeTab).label} - ${provinceFilter?.label ?? "Seluruh Indonesia"}`}
            </div>
            {/* <div
              className={`badge border-transparent px-3 py-1 text-sm bg-[#ECFDF3] text-primary-700`}
            >
              {`Total Nilai Agunan: ${new Intl.NumberFormat("id-ID", {
                style: "currency",
                currency: "IDR",
                maximumFractionDigits: 0,
              }).format(1000000000)}`}
            </div> */}
          </div>
          <div className="">
            <Table
              bordered
              layout="fixed"
              className="mb-4"
              columns={activeTab === 'tally_sheet' ? treeColumns : lembagaColumns}
              dataSource={collateralTable?.result ?? []}
              isLoading={isLoadingCollateralTable}
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              pagination={collateralTable?.paginator ?? {}}
            />
          </div>
        </div>
      {/* )} */}
    </div>
  );
};

export default CollateralDashboard;
