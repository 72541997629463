import { useQuery, useMutation } from "react-query";
import { COLLATERAL_HANDOVER } from "services";

export const useHandoverList = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ queryKey }) => {
      const params = queryKey[1];
      return COLLATERAL_HANDOVER.getHandoverList({ ...params });
    },
    ...options,
  });
};

export const useLandsList = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ queryKey }) => {
      const params = queryKey[1];
      return COLLATERAL_HANDOVER.getLands({ ...params });
    },
    ...options,
  });
};

export const useTreesList = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ queryKey }) => {
      const params = queryKey[1];
      return COLLATERAL_HANDOVER.getTrees({ ...params });
    },
    ...options,
  });
};

export const useCollateralsList = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ queryKey }) => {
      const params = queryKey[1];
      return COLLATERAL_HANDOVER.getCollaterals({ ...params });
    },
    ...options,
  });
};

export const useVehiclesList = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ queryKey }) => {
      const params = queryKey[1];
      return COLLATERAL_HANDOVER.getVehicles({ ...params });
    },
    ...options,
  });
};

export const useAgencyCollateralOption = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return COLLATERAL_HANDOVER.getAgencyCollateral({ ...params }, signal);
    },
    ...options,
  });
};

export const useAgencyCollateralTable = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return COLLATERAL_HANDOVER.getAgencyCollateralTable({ ...params }, signal);
    },
    ...options,
  });
};

export const useCollateralQuery = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return COLLATERAL_HANDOVER.getCollateralsList({ ...params }, signal);
    },
    ...options,
  });
};

export const useGetHandoverById = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ queryKey }) => {
      const params = queryKey[1];
      console.log('params <>>', params)
      return COLLATERAL_HANDOVER.getHandoverById({ ...params });
    },
    ...options,
  });
};

export const usePostHandover = () => {
  return useMutation((payload) => COLLATERAL_HANDOVER.createHandover(payload));
};