import axios from "axios";

export const getBankMasterData = async (params) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_MASTER_DATA}/Bank/list`,
    { params }
  );
  return data;
}

export const getProvinceMasterData = async (params) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_MASTER_DATA}/Provinsi/list`,
    { params }
  );
  return data;
}