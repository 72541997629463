import { Label } from "components";
import { useFormContext } from "react-hook-form";

export const RadioGroup = ({ label, name, options, onChange = () => {}, isView = false }) => {

  const methods = useFormContext();

  const {register, setValue} = methods
  
  return (
  <div className="space-y-2">
    <Label required>{label}</Label>
    <div className="flex space-x-6">
      {options.map((option) => (
        <label key={option.value} className="flex space-x-2 items-center">
          <input
            type="radio"
            className="peer border-gray-300 checked:bg-primary-600 checked:hover:bg-primary-600 checked:active:bg-primary-600 checked:focus:bg-primary-600 focus:bg-primary-600 focus:outline-none focus:ring-1 focus:ring-primary-600"
            {...register(name)}
            onChange={onChange}
            name={name}
            value={option.value}
            disabled={isView}
          />
          <span className="text-sm text-gray-700 peer-checked:font-semibold">
            {option.label}
          </span>
        </label>
      ))}
    </div>
  </div>
)};