import React, { useEffect, useMemo, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  Breadcrumbs,
  Card,
  CardHeader,
  CardBody,
  Label,
  Button,
  Table,
} from "components";
import {
  AsyncSelectForm,
  ButtonBackConfirm,
  CustomSelectForm,
  InputForm,
  TextareaForm,
} from "components/HookForm";
import { useNavigate, useParams } from "react-router";
import { BsArrowRightShort } from "react-icons/bs";

import TreeTable from "./Tables/TreeTable";
import CollateralTable from "./Tables/CollateralTable";
import LandTable from "./Tables/LandTable";
import VehicleTable from "./Tables/VehicleTable";
import {
  useAgencyCollateralOption,
  useAgencyCollateralTable,
  useGetHandoverById,
  usePostHandover,
  useTreesList,
} from "hooks/collateralHandover";
import moment from "moment";
import { enqueueSnackbar } from "notistack";
import ReactSelect from "react-select";
import CustomSelectFormv2 from "components/HookForm/CustomSelectFormv2";
import { RadioGroup } from "components/RadioGroup";
import AsyncDropzoneFieldForm from "components/HookForm/AsyncDropzoneFieldForm";

const LINKS = [
  {
    label: "Daftar Serah Terima Agunan",
    path: "/collateral/handover",
  },
  {
    label: "Tambah Rencana Penyerahan Agunan",
  },
];

const defaultValues = {
  category: "debitur",
  NAP: null,
  debitur: "",
  birthdate: "",
  payment: "",
  address: "",
  notes: "",
};

const validationSchema = yup.object({
  category: yup.string().required("Katgeori Serah Terima Agunan wajib diisi"),
  category_fdb: yup.string().required("Katgeori wajib diisi"),
  NAP: yup.object({ id: yup.string(), name: yup.string() }).required(),
  debitur: yup.string().required("Nama Debitur wajib diisi"),
  birthdate: yup.string().when("category_fdb", {
    is: "perorangan",
    then: () => yup.string().required("Tempat Lahir wajib diisi"),
    otherwise: () => yup.string().notRequired(),
  }),
  payment: yup.string().required("Nilai Pembayaran wajib diisi"),
  address: yup.string().required("Alamat wajib diisi"),
  notes: yup.string().when("category", {
    is: "another_party",
    then: () => yup.string().required("Catatan Kronologis wajib diisi"),
    otherwise: () => yup.string().notRequired(),
  }),
  supporting_file: yup.string().required("File pendukung wajib diisi"),
  request_id: yup.string(),
  province_id: yup.string(),
});

const NewHandover = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [pageTable, setPageTable] = useState(1);
  const [limitTable, setLimitTable] = useState(20);
  const [napOptions, setNapOptions] = useState([])
  const [category_fdb, setCategory_fdb] = useState('perorangan')
  const [request_id, setRequest_id] = useState(null)
  const [isView, setisView] = useState(false)

  const { id } = useParams();

  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const { setValue, watch, handleSubmit, reset, getValues } = methods;
  
  const { data: collateralDetail } = useGetHandoverById(["handover detail", {id}], {enabled: !!id});

  const { data , refetch } = useAgencyCollateralOption([
    "agency-collateral-options",
    {limit, page, 'filter[category]': category_fdb},
    {enabled: !!page},
  ]);

  const { data: collateralTable , refetch: refetchCollateralTable, isLoading: isLoadingCollateralTable } = useAgencyCollateralTable([
    "agency-collateral-table",
    {limit: limitTable, page: pageTable, 'filter[category]': category_fdb, request_id},
    { enabled: !!request_id },
  ]);

  const postHandover = usePostHandover();

  const onSubmit = () => {
    const data = getValues()
    let payload = {
      category: data.category,
      nap_number: data.category_fdb === "perorangan" ? data.NAP.value : '',
      name: data.debitur,
      birth_of_date: data.category_fdb === "perorangan" ?  data.birthdate : null,
      value: parseInt(data.payment),
      category_fdb: data.category_fdb,
      request_id: data.request_id,
      adress: data.address,
      notes: data.notes,
      supporting_file: data.supporting_file,
      province_id: data.province_id
    };

    if (data.category_fdb === 'perorangan') {
      const tallySheet = collateralTable?.data?.map(({ number, name, size, value }) => ({
        tree_number: number,
        name,
        size,
        price: value,
      })) || [];
  
      payload.tallySheet = tallySheet;
    }

    if (data.category_fdb === 'lembaga') {
      const prospecting = collateralTable?.data?.map(({ form, type, value }) => ({
        form, type, value
      })) || []

      payload.prospecting = prospecting;
    }

    postHandover.mutate(payload, {
      onSuccess: () => {
        navigate("/collateral/handover");

        enqueueSnackbar("Data berhasil dibuat.", {
          variant: "success",
        });
      },
      onError: (error, variables) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      },
    });
  };

  useEffect(() => {
    if (data) {
      const newOptions = data?.data?.data.map(item => ({
          label: category_fdb === 'perorangan' ? item?.nap_number : item?.name,
          value: category_fdb === 'perorangan' ? item?.nap_number : item?.name,
      }));

      // If napOptions already has values and category_fdb hasn't changed, append new options
      if (napOptions.length > 0 && category_fdb === watch('category_fdb')) {
        console.log('Sini')
          setNapOptions(prev => [...prev, ...newOptions]);
      } else {
        console.log('Sana')
          setNapOptions(newOptions); // Set new options if category has changed
      }
    }
  }, [category_fdb, data]);

  useEffect(() => {
    if (id) setisView(true)
  }, [id])

  const handleScrollBottom = () => {
    setPage((prevPage) => prevPage + 1);
    refetch();
  };

  useEffect(() => {
    if (watch("NAP")) {
      const selectedNAP = watch("NAP").value;
      const data_nap = data?.data?.data?.find(item => {
      if (category_fdb === 'lembaga') {
          return item.name === selectedNAP;
        }
        return item.nap_number === selectedNAP;
      });

      if (data_nap) {
        setValue("debitur", data_nap.name);
        setValue("birthdate", moment(data_nap.birth_of_date).format("DD-MM-YYYY"));
        setValue("payment", data_nap.value);
        setValue("address", data_nap.address);
        setValue("request_id", data_nap.request_id);
        setValue("province_id", data_nap.province_id);
      } else {
        console.warn("No matching NAP found for:", selectedNAP);
      }
    }
  }, [napOptions, setValue, watch("NAP")]);

  useEffect(() => {
    if (collateralDetail?.status === 200) {
      const { category, birth_of_date, nap_number, name, value, adress, notes, category_fdb, request_id, supporting_file } = collateralDetail?.data;
      reset({
        category_fdb: category_fdb ?? "perorangan",
        category: category ?? "debitur",
        birthdate: birth_of_date ? moment(birth_of_date).format("DD-MM-YYYY") : "",
        NAP: {label: category_fdb === 'lembaga' ? name : nap_number, value: category_fdb === 'lembaga' ? name : nap_number},
        debitur: name ?? "",
        payment: value ?? "",
        address: adress ?? "",
        notes,
        request_id,
        supporting_file,
      })
    }
  }, [collateralDetail, methods, reset]);

  useEffect(() => {
    if (watch('request_id')) {
      setRequest_id(watch('request_id'))
    }
  }, [watch('request_id')]);

  const handleCategoryFDB = (e) => {
    console.log('e.target.value', e.target.value);
    setPage(1);
    setNapOptions([]); // Reset napOptions when category_fdb changes
    setCategory_fdb(e.target.value);
    setRequest_id(null)
    setValue('request_id', '')
    setValue('NAP', '');
    setValue('debitur', '');
    setValue('birthdate', '');
    setValue('address', '');
    setValue('payment', '');
    refetch();
  };

  const treeColumns = useMemo(() => {
    return [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        render: (value, data, index) => {
          const no =
            parseInt(page) * parseInt(limit) - parseInt(limit) + index + 1;
          return no + ".";
        },
      },
      {
        id: "number",
        title: "No Pohon",
        dataIndex: "number",
        sortable: true,
        render: (value, data, index) => value ?? "-",
      },
      {
        id: "name",
        title: "Nama Pohon",
        dataIndex: "name",
        sortable: true,
        render: (value, data, index) => (value ? `${value}` : "-"),
      },
      {
        id: "size",
        title: "Ukuran",
        dataIndex: "size",
        sortable: true,
        render: (value, data, index) => (value ? `${value} cm` : "-"),
      },
      {
        id: "value",
        title: "Nilai Agunan",
        dataIndex: "value",
        sortable: true,
        render: (value, data, index) =>
          value
            ? new Intl.NumberFormat("id-ID", {
                style: "currency",
                currency: "IDR",
              }).format(value)
            : "-",
      },
    ]
  }, [page, limit])
  
  const lembagaColumns = useMemo(() => {
    return [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        render: (value, data, index) => {
          const no =
            parseInt(page) * parseInt(limit) - parseInt(limit) + index + 1;
          return no + ".";
        },
      },
      {
        id: "type",
        title: "Tipe Assets",
        dataIndex: "type",
        sortable: true,
        render: (value, data, index) => value ?? "-",
      },
      {
        id: "form",
        title: "Form Asset",
        dataIndex: "form",
        sortable: true,
        render: (value, data, index) => (value ? `${value} cm` : "-"),
      },
      {
        id: "value",
        title: "Nilai Agunan",
        dataIndex: "value",
        sortable: true,
        render: (value, data, index) =>
          value
            ? new Intl.NumberFormat("id-ID", {
                style: "currency",
                currency: "IDR",
              }).format(value)
            : "-",
      },
    ]
  }, [page, limit])

  return (
    <FormProvider {...methods}>
      <form noValidate autoComplete="off">
        <div className="space-y-6">
          <Breadcrumbs items={LINKS} />
          <Card>
            <CardHeader>Tambah Rencana Penyerahan Agunan</CardHeader>
            <CardBody>
              <div className="space-y-5">
                <RadioGroup
                  label="Pilih Kategori Serah Terima Agunan"
                  name="category"
                  options={[
                    { value: "debitur", label: "Diserahkan kepada debitur" },
                    { value: "another_party", label: "Diserahkan ke pihak lain" },
                  ]}
                  onChange={e => setValue('category', e.target.value)}
                  isView={isView}
                />

                <RadioGroup
                  label="Kategori"
                  name="category_fdb"
                  options={[
                    { value: "lembaga", label: "Lembaga" },
                    { value: "perorangan", label: "Perorangan" },
                  ]}
                  onChange={handleCategoryFDB}
                  isView={isView}
                />
                
                <div className="space-y-2 flex-1">
                  <Label required>{ watch("category_fdb") !== 'lembaga' ? "Nomor NAP" : "Nama Lembaga"}</Label>
                  <CustomSelectFormv2
                    options={napOptions}
                    name="NAP"
                    placeholder="Pilih Nomor NAP"
                    handleScrollBottom={handleScrollBottom}
                    asText={isView}
                    
                  />
                </div>
                <div className="flex space-x-6">
                  <div className="space-y-2 flex-1">
                    <Label required>{ watch("category_fdb") !== 'lembaga' ? "Nama Debitur" : "Nama Lembaga/Badan Usaha"}</Label>
                    <InputForm className="w-full" name="debitur" disabled />
                  </div>
                  {
                    watch("category_fdb") !== 'lembaga' && (
                      <div className="space-y-2 flex-1">
                        <Label required>Tanggal Lahir</Label>
                        <InputForm className="w-full" name="birthdate" disabled />
                      </div>
                    )
                  }
                  <div className="space-y-2 flex-1">
                    <Label required>Nilai Permohonan Pembiayaan</Label>
                    <InputForm className="w-full" name="payment" disabled />
                  </div>
                </div>
                <div className="space-y-2 flex-1">
                  <Label required>Alamat</Label>
                  <TextareaForm
                    className="w-full"
                    name="address"
                    rows={5}
                    disabled
                  />
                </div>
                <div className="space-y-2 flex-1">
                  <Label required>Upload File Pendukung</Label>
                  <AsyncDropzoneFieldForm
                    name="supporting_file"
                    informationText="PDF"
                    maxSize={1}
                    maxFiles={1}
                    isView={true}
                  />
                </div>
              </div>
            </CardBody>
          </Card>
          {watch("category") === "another_party" && (
            <Card>
              <CardHeader>Catatan Kronologis</CardHeader>
              <CardBody>
                <div className="space-y-2 flex-1">
                  <Label required>Catatan</Label>
                  <TextareaForm className="w-full" name="notes" rows={5} asText={isView} />
                </div>
              </CardBody>
            </Card>
          )}

          <div className="block md:flex items-center justify-between px-10 py-5 border-[2px] border-gray-200 rounded-3xl mt-4 bg-white mb-10">
            <ButtonBackConfirm
              useIcon={true}
              onConfirm={() => navigate("/collateral/handover")}
            />
            { !isView && (
              <Button
                type="button"
                className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 disabled:text-white disabled:border-primary-300 disabled:bg-primary-300"
                endIcon={<BsArrowRightShort className="text-white" size={20} />}
                onClick={() => handleSubmit(onSubmit)()}
              >
                Simpan
              </Button>
            )}
          </div>

          <Card>
            <CardHeader>Daftar Agunan</CardHeader>
            <CardBody>
              <div className="space-y-6">
                <div className="space-y-4">
                  <div className="font-semibold text-gray-900">
                    {
                      category_fdb === 'perorangan' ? "Daftar Pohon" : "Daftar Asset"
                    }
                  </div>
                  <Table
                    bordered
                    layout="fixed"
                    className="mb-4"
                    columns={category_fdb === 'perorangan' ? treeColumns : lembagaColumns}
                    dataSource={collateralTable?.data ?? []}
                    isLoading={isLoadingCollateralTable}
                    showPagination={false}
                    // onChangePage={onChangePage}
                    // onChangeRowsPerPage={onChangeRowsPerPage}
                    // pagination={collateralTable?.data?.paginator ?? {}}
                  />
                </div>
                <hr />
                {/* <div className="space-y-4">
                  <div className="font-semibold text-gray-900">
                    Daftar Collateral
                  </div>
                  <CollateralTable />
                </div>
                <hr />
                <div className="space-y-4">
                  <div className="font-semibold text-gray-900">
                    Daftar Tanah & Bangunan
                  </div>
                  <LandTable />
                </div>
                <hr />
                <div className="space-y-4">
                  <div className="font-semibold text-gray-900">
                    Daftar Kendaraan & Mesin
                  </div>
                  <VehicleTable />
                </div> */}
              </div>
            </CardBody>
          </Card>
        </div>
      </form>
    </FormProvider>
  );
};

export default NewHandover;
