import { useQuery } from "react-query";
import { MASTER_DATA } from "services";

export const useBankMasterData = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ queryKey }) => {
      const params = queryKey[1];
      return MASTER_DATA.getBankMasterData({ ...params });
    },
    ...options,
  });
}

export const useProvinceMasterData = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ queryKey }) => {
      const params = queryKey[1];
      return MASTER_DATA.getProvinceMasterData({ ...params });
    },
    ...options,
  });
}