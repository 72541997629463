import axios from "axios";

export const getDashboard = async (params, signal) => {
  const {data} = await axios.get(`${process.env.REACT_APP_BASE_API_URL}/perjanjian/v1/agreement-dashboard-collateral`, {params, signal});
  return data
};

export const getDashboardTable = async (params, signal) => {
  const {data} = await axios.get(`${process.env.REACT_APP_BASE_API_URL}/perjanjian/v1/agreement-dashboard-collateral/agunan`, {params, signal});
  return data
};